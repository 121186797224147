import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdvertiserComponent } from './admon/pages/advertiser/advertiser.component';
import { CampaignsComponent } from './admon/pages/campaigns/campaigns.component';
import { CampaignComponent } from './user/pages/campaign/campaign.component';
import { CreateAdvertiserComponent } from './admon/pages/advertiser/create-advertiser/create-advertiser.component';
import { CreateCampaignComponent } from './admon/pages/campaigns/create-campaign/create-campaign.component';
import { CreateRegionComponent } from './admon/pages/region/create-region/create-region.component';
import { HomeComponent } from './user/pages/home/home.component';
import { LoginComponent } from './auth/pages/login/login.component';
import { PreviewComponent } from './user/pages/preview/preview.component';
import { PreviewDetailComponent } from './user/pages/preview-detail/preview-detail.component';
import { PreviewShowcaseComponent } from './user/pages/preview-showcase/preview-showcase.component';
import { RegionComponent } from './admon/pages/region/region.component';
import { UserComponent } from './admon/pages/user/user.component';
import { CreateUserComponent } from './admon/pages/user/create-user/create-user.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'preview',
    component: PreviewComponent
  },
  {
    path: 'preview-showcase',
    component: PreviewShowcaseComponent
  },
  {
    path: 'preview-deta',
    component: PreviewDetailComponent
  },
  {
    path: 'campaigns',
    component: CampaignsComponent
  },
  {
    path: 'campaign-create',
    component: CreateCampaignComponent
  },
  {
    path: 'campaigns-user',
    component: CampaignComponent
  },
  {
    path: 'users',
    component: UserComponent
  },
  {
    path: 'users-create',
    component: CreateUserComponent
  },
  {
    path: 'advertiser',
    component: AdvertiserComponent
  },
  {
    path: 'advertiser-create',
    component: CreateAdvertiserComponent
  },
  {
    path: 'region',
    component: RegionComponent
  },
  {
    path: 'region-create',
    component: CreateRegionComponent
  },
  {
    path: '**',
    redirectTo: ''
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
