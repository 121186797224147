<form [formGroup]="formularioAdicionar" autocomplete="off">
  <app-loading *ngIf="loading"></app-loading>
  <div class="showpreview">
      <div class="showpreview__sidebar" *ngIf="showFilter">
          <div>
              <div class="showpreview__sidebar-intro">
                  <h3>PREVIEW FILTER</h3>
              </div>
              <div class="showpreview__sidebar-body"
                  *ngIf="showCampaign">
                  <div *ngFor="let filter of filters">
                    <div *ngIf="filter.filter.filter_size === 0">
                      <h4>{{filter.filter.name}}</h4>
                      <mat-form-field appearance="outline" class="select-show">
                          <mat-label>Select {{filter.filter.name}}</mat-label>
                          <mat-select [formControlName]="filter.filter.name">
                              <mat-option value="">All</mat-option>
                              <mat-option *ngFor="let data of filter.data" [value]="data.data">
                                  {{data.data}}
                              </mat-option>
                          </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div>
                      <h4>Format</h4>
                      <section>
                          <mat-checkbox
                              class="check-filters-preview"
                              formControlName="checked"
                              (change)="selectAll()">
                              <span>All</span>
                          </mat-checkbox>
                          <mat-checkbox *ngFor="let format of campaignFormats"
                              [formControlName]="'format_' + format.id"
                              [Value]="format.id"
                              (change)="validate($event, format.id)"
                              class="check-filters-preview">
                              <span>{{format.name}}</span>
                          </mat-checkbox>
                      </section>
                  </div>
              </div>
          </div>
          <div class="showpreview__sidebar-footer">
            <button class="btn btn-showcase mb-2"
            (click)="getData()">
            GENERATE PREVIEW
            </button>
          </div>
      </div>
      <div class="showpreview__creatives">
        <button (click)="showFilters()" class="btn-show-filterbar">
            <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#fff"><path d="M144-264v-72h672v72H144Zm0-180v-72h672v72H144Zm0-180v-72h672v72H144Z"/></svg>
        </button>
          <div style="padding-bottom: 80px;">
              <div style="display: flex; flex-wrap: wrap; align-items: flex-start;" *ngIf="filteredFormats().length > 0">
                  <div class="card-iframe" *ngFor="let format of filteredFormats(); let i = index">
                      <div class="card-iframe__info">
                          <span class="card-iframe__info-size">
                              {{format.name}}
                          </span>
                          <button class="card-iframe__info-reload"
                              (click)="refreshIframe(i + '-' + (format.data?.['field_1']?.toString() || ''))">
                              <img alt="Refresh Icon" src="/assets/img/reload-icon.png">
                          </button>
                          <button class="card-iframe__info-size d-none"
                              (click)="openTab(format.data?.['id']?.toString()!, campaign.id, format.id)">
                              Open New tab
                          </button>
                          <button class="card-iframe__info-size d-none"
                              (click)="sharedDeta(format.data?.['id']?.toString()!, campaign.id, format.id)">
                              Shared
                          </button>
                          <button class="card-iframe__info-reload d-none"
                              (click)="generatePrint('iframe-' + i + '-' + (format.data?.['field_1']?.toString() || ''))">
                              <img alt="Print" src="/assets/img/reload-icon.png">
                          </button>
                      </div>
                      <span class="title-creative">{{(format.data?.['field_2']?.toString() || '')}}</span>
                      <iframe [src]="format.safeHtml"
                          [width]="format.width"
                          [height]="format.height"
                          [attr.id]="'iframe-' + i + '-' + (format.data?.['field_1']?.toString() || '')">
                          <p>Your browser does not support iframes.</p>
                      </iframe>
                      <br>
                      <div class="mt-2"
                          *ngIf="user">
                        <mat-accordion>
                            <mat-expansion-panel  >
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                Comments
                                </mat-panel-title>
                                <mat-panel-description>
                                Click to see details
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <div>
                                <button class="btn btn-secondary"
                                    (click)="addComment(format.id, (format.data?.['field_1']?.toString() || ''), approved, true)">
                                    Approve
                                </button>
                                <button class="btn btn-secondary d-none"
                                        (click)="createIframe(i + '-' + (format.data?.['field_1']?.toString() || ''), format.html)">
                                        Create
                                </button>
                                <button class="btn btn-secondary d-none"
                                        (click)="captureIframe(i + '-' + (format.data?.['field_1']?.toString() || ''))">
                                        Capture
                                </button>
                                <div *ngIf="capturedImage">
                                <img [src]="capturedImage" alt="Captured Image"/>
                                </div>
                                <br />
                                <div class="mt-2 custom-scroll-div">
                                    <div class="mt-2" *ngFor="let comment of format.comments">
                                        <div *ngIf="comment.variation_id.toString() === (format.data?.['field_1']?.toString() || '')">
                                        <mat-label>
                                            <b>{{ comment.created_date | date: 'dd/MM/yyyy, h:mm a' }}:</b> {{ comment.comments }} <br/> <b>User:</b> {{ comment.user }}
                                        </mat-label>
                                        <div [class]="comment.status === 'P' ? 'custom-chip red-chip' : 'custom-chip green-chip'"
                                            (click)="updateStatus(comment.id, format.id, comment.status)">{{ comment.status | status }}</div>
                                        </div>
                                    </div>
                                </div>
                                <mat-form-field appearance="fill">
                                <mat-label>Comments</mat-label>
                                <input matInput
                                        placeholder="Add comments"
                                        [formControlName]="'comment_' + format.id + '_' + (format.data?.['field_1']?.toString() || '')" />
                                </mat-form-field>
                                <button class="btn btn-secondary"
                                    (click)="addComment(format.id, (format.data?.['field_1']?.toString() || ''), pending)">
                                    Add
                                </button>
                            </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                      </div>
                  </div>
              </div>
          </div>
          <div class="row" class="paginator-showcase">
            <div class="col-md-12">
              <div fxLayout="row" class="text-center {{ loading === true ? 'd-none' : '' }}">
              <mat-paginator #paginator
                  [length]="total"
                  pageSize="30"
                  (page)="obtenerDataPagina($event)"
                  [pageSizeOptions]="[30]"
                  showFirstLastButtons="true"
                  aria-label="Select page">
              </mat-paginator>
              </div>
            </div>
          </div>
      </div>
  </div>
</form>
